'use client';

import './input-birth-date.scss';

import cx from 'classix';
import { isValid } from 'date-fns';
import { useTranslations } from 'next-intl';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
// TODO: Use <Select /> component instead of import react-select directly
import Select, {
  type SelectInstance,
  type SingleValue,
} from 'react-select';
import { useIsMounted } from 'usehooks-ts';

import styles from '@/features/user/components/user-birthday-form/user-birthday-form.module.scss';
import {
  type UserBirthday,
  type UserBirthdayInputName,
} from '@/features/user/types/user-profile-types';
import { type UiSizesTypes } from '@/shared/types/ui-sizes-types';

export interface DateOption {
  label: number;
  value: number;
}

interface InputBirthDateProps {
  birthDate: Partial<UserBirthday> | null;
  onBirthDateChange: (inputName: UserBirthdayInputName, value: number) => void;
  onErrorsUpdate: (isError: boolean) => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
  size?: UiSizesTypes;
}

export default function InputBirthDate(props: InputBirthDateProps) {
  const {
    autoFocus,
    birthDate,
    isDisabled,
    onBirthDateChange,
    onErrorsUpdate,
    size,
  } = props;

  const translationsDates = useTranslations('global.date');
  const translationsErrors = useTranslations('global.form.errors');

  const isMounted = useIsMounted();

  const [birthDateError,
    setBirthDateError] = useState<string | null>(null);

  const daysSelectRef = useRef<SelectInstance<DateOption>>(null);
  const monthsSelectRef = useRef<SelectInstance<DateOption>>(null);
  const yearsSelectRef = useRef<SelectInstance<DateOption>>(null);

  const currentYear = new Date().getFullYear();
  const yearsOptions: DateOption[] = Array.from({ length: 103 }, (_, index) => {
    const mapYear = currentYear - 18 - index;
    return { label: mapYear, value: mapYear };
  });
  const monthsOptions: DateOption[] = Array.from({ length: 12 }, (_, index) => {
    const mapMonth = index + 1;
    return { label: mapMonth, value: mapMonth };
  });

  const selectedYear = yearsOptions.find((year) => year.value === birthDate?.birth_year);
  const selectedMonth = monthsOptions.find((month) => month.value === birthDate?.birth_month);

  const getDaysInSelectedMonth = (month: number, year: number): number => new Date(year, month, 0).getDate();
  const daysOptions: DateOption[] = Array.from({ length: getDaysInSelectedMonth(birthDate?.birth_month ?? 1, birthDate?.birth_year ?? currentYear) }, (_, index) => {
    const mapDay = index + 1;
    return { label: mapDay, value: mapDay };
  });
  const selectedDay = daysOptions.find((day) => day.value === birthDate?.birth_day);

  const checkIsDayValid = (year: number, month: number, day: number) => getDaysInSelectedMonth(month, year) >= day;

  const validateDate = (
    year: number | null = null,
    month: number | null = null,
    day: number | null = null,
  ): void => {
    let isDateValid = true;
    if (year && month && day) {
      const fullDate = new Date(year, month - 1, day);
      isDateValid = isValid(fullDate) && checkIsDayValid(year, month, day);
    }

    setBirthDateError(isDateValid ? null : translationsErrors('invalid-date'));
    onErrorsUpdate(!isDateValid);
  };

  const handleYearSelectChange = (option: SingleValue<DateOption>): void => {
    if (option) {
      onBirthDateChange('birth_year', option.value);
      monthsSelectRef.current?.focus();
    }

    validateDate(option?.value, birthDate?.birth_month, birthDate?.birth_day);
  };

  const handleMonthsSelectChange = (option: SingleValue<DateOption>): void => {
    if (option) {
      onBirthDateChange('birth_month', option.value);
      daysSelectRef.current?.focus();
    }

    validateDate(birthDate?.birth_year, option?.value, birthDate?.birth_day);
  };

  const handleDaySelectChange = (option: SingleValue<DateOption>): void => {
    if (option) {
      onBirthDateChange('birth_day', option.value);
    }

    validateDate(birthDate?.birth_year, birthDate?.birth_month, option?.value);
  };

  useEffect(() => {
    if (autoFocus) {
      yearsSelectRef.current?.focus();
    }
  }, [autoFocus, isMounted]);

  return (
    <div className={cx(styles.birthdayForm_birthdayFields, size && styles[`birthdayForm_birthdayFields__${size}`])}>
      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('year')}
        </div>

        <Select
          className={cx('birthdaySelect', size && `birthdaySelect__${size}`)}
          classNamePrefix="birthdaySelect"
          ref={yearsSelectRef}
          value={selectedYear}
          onChange={handleYearSelectChange}
          name="birth_year"
          options={yearsOptions}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('month')}
        </div>

        <Select
          className={cx('birthdaySelect', size && `birthdaySelect__${size}`)}
          classNamePrefix="birthdaySelect"
          ref={monthsSelectRef}
          value={selectedMonth}
          onChange={handleMonthsSelectChange}
          name="birth_month"
          options={monthsOptions}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('day')}
        </div>

        <Select
          className={cx('birthdaySelect', size && `birthdaySelect__${size}`)}
          classNamePrefix="birthdaySelect"
          ref={daysSelectRef}
          value={selectedDay}
          onChange={handleDaySelectChange}
          name="birth_day"
          options={daysOptions}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>
      {birthDateError && (
        <div className={styles.birthdayForm_birthdayError}>
          {birthDateError}
        </div>
      )}
    </div>
  );
}
